export default [
	"unstyled",
	"bullet",
	"bullet-outline",
	"checkmark",
	"icon",
	"numbered",
	"numbered-filled",
	"numbered-outline",
];
